<template>
  <dash-page-new
      :title="block.name"
      :subtitle="$t('Block')"
      :root="$t('SelectedTemplate')"
      :side-navigation="templateId ? avalonAdminTemplateNavigationItems : null"
      :navigation-return-route="localeLink('avalon_editor/templates/' + this.templateId)"
  >

  <div class="py-16">

    <blockEditor
        :block="block"
        :elements="elements"
        :triggerUpdate="triggerUpdate"
        template
    />

  </div>
  </dash-page-new>
</template>

<script>
import blockEditor from "@/components/AvalonEditor/Editor/blocks/BlockEditor";
import {mapActions} from "vuex";

export default {
  name: "adminBlockEdit",
  components : {
    blockEditor
  },
  props : {
    id : {
      type : String
    },
    templateId : {
      type : String
    }

  },
  data() {
    return {
      block : {},
      elements : [],
      triggerUpdate : 0
    }
  },
  methods : {
    ...mapActions('avalon',[
        'GET_ADMIN_TEMPLATE_BLOCK'
    ]),
  },
  async mounted() {
    let result = await this.GET_ADMIN_TEMPLATE_BLOCK(this.id)
    if (! result ) {
      return
    }
    this.block = result.block
    this.elements = result.elements
    this.triggerUpdate++
  }

}
</script>

<style scoped>

</style>